import './LoadingSpinner.css'
import { useTranslation } from 'react-i18next';

const LoadingSpinner = ({ message }: { message: string }) => {
	const { t } = useTranslation("common");

	return (
		<div className="loadin-spinner-wrapper">
			<div className="loading-spinner"></div>
			<span className='loading-message'>{t(message)}</span>
		</div>
	)
}

export default LoadingSpinner;