import './Message.css';
import { useTranslation } from 'react-i18next';

const Message = ({ message }: { message: string }) => {
	const { t } = useTranslation("common");

	return (
		<div className={`message-wrapper danger`}>
			<div className="icon">{'!'}</div>
			<div className="text">{t(message)}</div>
		</div>
	)
}
export default Message;