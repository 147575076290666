import {
	CustomWidgetCollection,
	RegexValidator,
	Serializer
} from "survey-core";

const CUSTOM_TYPE = "customdate";
const REGEX_DISPLAY_VALUE = "^(0[1-9]|1[0-2])\\/(0[1-9]|[12]\\d|3[01])\\/\\d{4}$";
const REGEX_STORED_VALUE = "^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01])$";

export function init() {
	// Function to format the display value (mm/dd/yyyy)
	function formatDisplayValue(storedValue: string): string {
		if (!storedValue) return '';
		const [yyyy, mm, dd] = storedValue.split('-');
		return `${mm}/${dd}/${yyyy}`;
	}

	// Function to format stored value (yyyy-dd-mm)
	function formatStoredValue(displayValue: string): string {
		const [mm, dd, yyyy] = displayValue.split('/');
		return `${yyyy}-${mm}-${dd}`;
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, question, input) => {
		// When the length is valid, convert and save the value in the stored format yyyy-dd-mm
		if (input.value) {
			const [mm, dd, yyyy] = input.value.split('/');
			question.value = formatStoredValue(input.value);
		}
		// Check if there are errors (and show message automatically)
		question.hasErrors();
	}

	// Triggered before
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, input) => {
		// Only input numbers
		if (/[^0-9/]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
			e.preventDefault();
		}
	}

	// Triggered after
	const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, input) => {
		// Automatically add slashes for the format mm/dd/yyyy
		if (input.value.length === 2 || input.value.length === 5) {
			input.value += '/';
		}
	}

	var widget = {
		//the widget name. It should be unique and written in lowcase.
		name: CUSTOM_TYPE,
		//the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
		title: "Custom date",
		//the name of the icon on the toolbox. We will leave it empty to use the standard one
		iconName: "",
		//If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
		widgetIsLoaded: function () {
			return true; //we do not require anything so we just return true.
		},
		//SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
		isFit: function (question) {
			//the following code will activate the widget for a text question with inputType equals to date
			return question.getType() === "text" && question.inputType === "date";
			// return question.inputType === CUSTOM_TYPE;
		},
		//Use this function to create a new class or add new properties or remove unneeded properties from your widget
		//activatedBy tells how your widget has been activated by: property, type or customType
		//property - it means that it will activated if a property of the existing question type is set to particular value, for example inputType = "date"
		//type - you are changing the behaviour of entire question type. For example render radiogroup question differently, have a fancy radio buttons
		//customType - you are creating a new type, like in our example "textwithbutton"
		activatedByChanged: function (activatedBy) {
			// We do not need to check acticatedBy parameter, since we will use our widget for customType only
			// We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
			Serializer.addClass(
				CUSTOM_TYPE,
				[],
				undefined,
				"text"
			);
			// JsonObject.metaData.addClass(CUSTOM_TYPE, [], undefined, "text");
		},
		//If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our our htmlTemplate
		isDefaultRender: false,
		//You should use it if your set the isDefaultRender to false
		htmlTemplate: `<input type="text" class="sd-input sd-text" placeholder="mm/dd/yyyy" maxLength="10" />`,
		//The main function, rendering and two-way binding
		afterRender: function (question, el) {
			// Get HTML element
			var element = el.getElementsByTagName("input")[0];

			// Add the format validation
			if (question.validators.length === 0) {
				const regexValidator = new RegexValidator(REGEX_STORED_VALUE);
				regexValidator.text = "Please enter a valid date in the format mm/dd/yyyy";
				question.validators.push(regexValidator);
			}

			// Handle events
			element.onkeydown = (e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, element);
			element.onkeyup = (e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e, element);
			element.onchange = (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, question, element);

			// If question becomes readonly/enabled add/remove disabled attribute
			question.readOnlyChangedCallback = () => {
				if (question.isReadOnly) {
					element.setAttribute("disabled", "disabled");
				} else {
					element.removeAttribute("disabled");
				}
			};
			question.readOnlyChangedCallback();
		},
		// Use it to destroy the widget. It is typically needed by jQuery widgets
		willUnmount: function (question, el) {
		},
	};

	// Register our widget in singleton custom widget collection
	CustomWidgetCollection.Instance.add(widget);
}