import './Header.css';
import i18next from 'i18next';

const Header = ({ 
	languages, 
	brandLogo,
	onLangChange 
}: { 
	languages: string[], 
	brandLogo: string | null,
	onLangChange: () => void 
}) => {

	const defaultLangs = Object.keys(i18next.services.resourceStore.data);

	// Manual mappings (languages not included in Intl.DisplayNames)
	const languageMapping = {
		"en": { "mh": "Marshallese" },
		"en-US": { "mh": "Marshallese" },
		"es": { "mh": "Marshalés" },
		"fr": { "mh": "Marshallais" },
		"ht": { 
			"en": "Angle",
			"es": "Panyòl",
			"fr": "Franse",
			"ht": "Kreyòl Ayisyen",
			"mh": "Marshallese" 
		},
		"mh": { 
			"en": "Iñlij",
			"es": "Jipein",
			"fr": "Būranij",
			"ht": "Haitian Creole",
			"mh": "Ṃajeḷ" 
		},
	};

	return (
		<header className='app-header'>

			{/* 
				Brand logo (Kannact/Starlight) 
				Note: the empty span is to maintain the alignment while obtaining the logo
			*/}
			{brandLogo ? 
				(<img src={brandLogo || ''} alt="Logo" className='header-logo' />) : 
				(<span></span>)
			}

			{/* Select: Available languages */}
			{i18next.language && (languages.length > 0 || defaultLangs.length > 0) && (
				<div className='lang'>
					<select
						style={{ textTransform: 'capitalize' }}
						defaultValue={i18next.language}
						onChange={(e) => {
							i18next
								.changeLanguage(e.target.value) // Update app language
								.then(() => onLangChange()); // Update SurveyJS form language
						}}>
						{(languages.length > 0 ? languages : defaultLangs).map((x) => (
							<option key={x} value={x}>
								{languageMapping[i18next.language] && languageMapping[i18next.language][x] ? 
									languageMapping[i18next.language][x] : 
									new Intl.DisplayNames([i18next.language], { type: 'language' }).of(x) ?? x}
							</option>
						))}
					</select>
				</div>
			)}

		</header>
	);
}
export default Header;