import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import en from './assets/i18n/en-US.json';
import es from './assets/i18n/es-ES.json';
import fr from './assets/i18n/fr-FR.json';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { I18nService } from './services/i18n';

// i18n: Internationalization
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: I18nService.getBrowserLang(), // language to use
  fallbackLng: 'en',
  supportedLngs: ['en', 'es', 'fr'],
  resources: {
    en: {
      common: en
    },
    es: {
      common: es
    },
    fr: {
      common: fr
    }
  }
});

// Use the browser language. Otherwise use the fallback language ('en')
// locale(!i18next.languages.includes(i18next.language) ? 'en' : i18next.language);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
